.PageNotFound-module__pageNotFound___vrogg {
  text-align: center;
  margin-top: 100px;
}
.PageNotFound-module__centerText___1tiYI {
  margin: 20px 20px;
}
.PageNotFound-module__pageNotFoundImage___335kH {
  width: 400px;
}
.PageNotFound-module__pageNotFoundTitle___1DuYp {
  margin-top: 20px;
}
.PageNotFound-module__pageNotFoundText___2pOSW {
  margin-top: 10px;
  padding: 0px 10px;
}
.PageNotFound-module__pageNotFoundButton___3z58J {
  margin-top: 25px;
  width: 95px;
}
.PageNotFound-module__pageNotFoundButtonColor___21350 {
  color: white;
}
@media only screen and (min-width: 600px) {
  .PageNotFound-module__pageNotFoundImage___335kH {
    width: 600px;
  }
}
